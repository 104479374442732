import React from 'react';
import vermiculite from '../../images/vermiculite.jpg';
import cocopeat from '../../images/cocopeat.jpeg';
import peatmoss from '../../images/peat-moss.jpeg';
import perlite from '../../images/perlite/perlite_cropped.jpg';
import firelighter from '../../images/firelighter.jpg';
import SmallProduct from './SmallProduct';

const SmallProductList = () => {
  return (
    <div data-aos="fade-up"
    data-aos-duration="900">

      <div className="surface-ground px-4 py-5 md:px-6 lg:px-8">
        <div className="border-bottom-1 surface-border">
          <span className="block text-3xl fw-600 text-900 mb-4" id="products-preview">Products</span>
        </div>
      </div>

      <div className="surface-ground px-4 py-5 md:px-6 lg:px-8">
        <div className="grid">
          <SmallProduct pic={vermiculite} productName="Vermiculite" link="vermiculite"
          delay="100" />
          <SmallProduct pic={peatmoss} productName="Peat Moss" link="peat-moss"
          delay="200" />
          <SmallProduct pic={cocopeat} productName="Cocopeat" link="cocopeat"
          delay="300" />
          <SmallProduct pic={perlite} productName="Perlite" link="perlite"
          delay="400" />
          <SmallProduct pic={firelighter} productName="Firelighter" link="firelighter"
          delay="400" />
        </div>
      </div>

    </div>
  );
};

export default SmallProductList;
