import * as React from 'react';

import { connect } from 'react-redux';
import Footer from '../components/Footer';
import IntroHeaderSec from '../components/intro-header/IntroHeaderSec';
import SmallAboutSec from '../components/intro-header/SmallAboutSec';
import SmallProductList from '../components/intro-header/SmallProductList';
import Layout from '../components/layout';
import Seo from '../components/seo';

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <IntroHeaderSec />
      <SmallAboutSec />
      <SmallProductList />
      <Footer />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(IndexPage);
