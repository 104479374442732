import React from 'react';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { navigate } from 'gatsby-link';
import { checkPageLoading } from '../../state/auth/auth.actions';

const SmallAboutSec = ({ checkPageLoading }) => {
  return (
    <div
      className="white-bg px-4 py-8 md:px-6 lg:px-8"
      data-aos="fade-up"
      data-aos-duration="900"
    >
      <div>
        <div
          className="gray-c font-bold text-5xl mb-3"
          data-aos="flip-up" data-aos-duration="1100"
        >
          Ecolite Minerals LTD
        </div>
        <div
          className="text-gray-500 text-2xl line-height-3"
          data-aos="flip-down" data-aos-duration="1100"
        >
          Ecolite minerals Ltd is a company dedicated popularise vermiculite
          above other substitutes and to develop value added products like...
        </div>
        <div data-aos="fade-up-right" data-aos-duration="1100">
          <Button
          label="About Us"
          icon="pi pi-chevron-right"
          iconPos="right"
          className="green-bg border-0 p-button-rounded mt-3 font-bold shadow-5"
          onClick={(ev) => {
            ev.preventDefault();
            checkPageLoading(true);
            navigate('/about');
          }}
        />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  checkPageLoading: (state) => dispatch(checkPageLoading(state))
});

export default connect(null, mapDispatchToProps)(SmallAboutSec);
