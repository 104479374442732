import { navigate } from 'gatsby-link';
import React from 'react';
import { connect } from 'react-redux';
import { checkPageLoading } from '../../state/auth/auth.actions';

const SmallProduct = ({ pic, productName, link, checkPageLoading, delay }) => {
  const handleClick = (ev) => {
    ev.preventDefault();
    checkPageLoading(true);
    navigate(`/${link}`);
  };
  return (
    <div className="col-12 lg:col-4 p-2" style={{ minHeight: '390px' }} onClick={handleClick}
    data-aos="fade-up-left"
      data-aos-duration="1100"
      data-aos-delay={delay}>
      <div className="surface-card border-round h-full cursor small-product"
            style={{ backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${pic})` }}>
        <div className="w-100 d-flex column h-100 pl-2" style={{ justifyContent: 'flex-end' }}>
          <h1 style={{ fontSize: 'calc(1.325rem + 0.9vw)', color: 'white' }}>{productName}</h1>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  checkPageLoading: (state) => dispatch(checkPageLoading(state))
});

export default connect(null, mapDispatchToProps)(SmallProduct);
